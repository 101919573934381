import React from "react";



// context
import { Grid } from "@material-ui/core";
import { useUserState } from "../../context/UserContext";
import HomeAdmin from "../../pages/admin/Home";

function Layout(props) {


  return (        <>
          <Grid container>
            <Grid item xs={1} />
            <Grid item xs={10}>
              <HomeAdmin />
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </>
  );
}

export default Layout;
