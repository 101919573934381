import React, { useEffect } from "react";
import {
    IconButton,
    Box,
    Button,
    FormControl,
    Input,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Typography,
    Chip,
    Checkbox,
    ListItemText,
} from "@material-ui/core";

import MUIDataTable from "mui-datatables";
import { getCourses, getToken, updateTeacher } from "../../services/api/Api";

import useStyles from "./styles";

// Iconos
import { Edit, Delete, Receipt } from "@material-ui/icons";
import { idea } from "react-syntax-highlighter/dist/esm/styles/hljs";

export default function TablaProfesores({ profesores, setProfesores, seElimino, setSeElimino }) {

    const [editProfesor, setEditProfesor] = React.useState(null);
    const [contrasena, setContrasena] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [cursos, setCursos] = React.useState([]);
    const [preguntaBorrador, setPreguntaBorrador] = React.useState(false);
    const [tableIndex, setTableIndex] = React.useState(null);
    const cerrarModal = () => setOpen(false);
    var classes = useStyles();

    const options = {
        filterType: "checkbox",
        download: false,
        print: false,
        selectableRows: "none",
    };

    React.useEffect(() => {
        getToken().then((token) => {
            getCourses(token).then((data) => {
                setCursos(data);
            });
        });
    }, []);

    const columns = [
        {
            name: "Nombre",
            label: "Nombre",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "Correo",
            label: "Correo",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "Telefono",
            label: "Telefono",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "Acciones",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div>
                            <IconButton
                                aria-label="editar"
                                onClick={(e) => {
                                    const profesor = profesores[tableMeta.rowIndex];
                                    setEditProfesor(profesor);
                                    setOpen(true);
                                }}
                            >
                                <Edit />
                            </IconButton>
                        </div>
                    );
                },
            },
        },
    ];

    const mostrarNombreCurso = (id) => {
        let nombre = '';
        let id_curso = editProfesor.Cursos[id].Curso;
        cursos.filter((curso) => {
            if (curso.key === id_curso) {
                nombre = curso.Nombre
            }
        });
        return nombre;
    }

    const mostrarChecked = (id) => {
        // Buscamos en editProfesor.Cursos el id del curso que queremos comprobar
        // Pero este no es un array, es un objeto, el key es el id del curso con una estructura
        // {id_asignacion1: {Curso: id_curso}, id_asignacion2: {Curso: id_curso} }
        let checked = false;
        if(editProfesor?.Cursos){
            Object.keys(editProfesor?.Cursos).forEach((key) => {
                if (editProfesor?.Cursos[key]?.Curso === id) {
                    checked = true;
                }
            });
        }
        return checked;
    }

    const actualizarProfesor = () => {
        if(editProfesor === null){
            return;
        }
        const profesor = {
            id: editProfesor?.key,
            Nombre: editProfesor?.Nombre,
            Cursos: editProfesor?.Cursos,
            Contrasena: contrasena,
            Correo: editProfesor?.Correo,
            Telefono: editProfesor?.Telefono,
            Borrado: editProfesor?.Borrado
        }

        updateTeacher(profesor).then(() => {
            // Actualizar el profesor en profesores
            const newProfesores = [...profesores];
            // Filtrar todos los profesores menos el que se va a editar
            newProfesores.splice(editProfesor?.key, 1);
            // Añadir el profesor editado
            newProfesores.push(profesor);
            setProfesores(newProfesores);
            
            setOpen(false);
        });

        if(profesor.Borrado === true){
            setSeElimino(true);
        }
    }

    const modalEditar = () => {
        return (
            <Modal open={open} onClose={cerrarModal}>
                <Box className={classes.modal}>
                    <Typography variant="h6" className="titulo">Editar profesor</Typography>
                    <FormControl fullWidth>
                        <InputLabel>Nombre</InputLabel>
                        <Input type="text" value={editProfesor?.Nombre} onChange={(e) => {
                            setEditProfesor({
                                ...editProfesor,
                                Nombre: e.target.value
                            })
                        }} />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Correo</InputLabel>
                        <Input type="email" value={editProfesor?.Correo} onChange={(e) => {
                            setEditProfesor({
                                ...editProfesor,
                                Correo: e.target.value
                            })
                        }} />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Teléfono</InputLabel>
                        <Input type="text" value={editProfesor?.Telefono} onChange={(e) => {
                            setEditProfesor({
                                ...editProfesor,
                                Telefono: e.target.value
                            })
                        }} />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Cursos</InputLabel>
                        <Select
                            multiple
                            value={Object.keys(editProfesor?.Cursos || [])}
                            onChange={(e) => {
                                const cursosSeleccionados = e.target.value;
                                const nuevosCursos = {};
                                cursos.forEach((curso) => {
                                    if (cursosSeleccionados.includes(curso.key)) {
                                        nuevosCursos[curso.key] = { Curso: curso.key };
                                    } else if (editProfesor?.Cursos?.[curso.key]) {
                                        delete nuevosCursos[curso.key];
                                    }
                                });
                                setEditProfesor({
                                    ...editProfesor,
                                    Cursos: {
                                        ...nuevosCursos
                                    }
                                });
                            }}
                            renderValue={(selected) => (
                                <div>
                                    {selected.map((cursoId) => (
                                        <Chip key={cursoId} label={mostrarNombreCurso(cursoId)} />
                                    ))}
                                </div>
                            )}
                        >
                            {cursos.map((curso) => (
                                <MenuItem key={curso.key} value={curso.key}>
                                    <Checkbox
                                        checked={mostrarChecked(curso.key)}
                                        onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            setEditProfesor((prevEditProfesor) => {
                                                const updatedCursos = { ...prevEditProfesor.Cursos };
                                                if (isChecked) {
                                                    updatedCursos[curso.key] = { Curso: curso.key };
                                                } else {
                                                    delete updatedCursos[curso.key];
                                                }
                                                return {
                                                    ...prevEditProfesor,
                                                    Cursos: updatedCursos
                                                };
                                            });
                                        }}
                                    />
                                    <ListItemText primary={curso.Nombre} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Asignar nueva contraseña</InputLabel>
                        <Input type="password" value={contrasena} onChange={(e) => setContrasena(e.target.value)} />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>¿Deseas borrar este registro?</InputLabel>
                        <Select
                            value={editProfesor?.Borrado}
                            onChange={(e) => {
                                setEditProfesor({
                                    ...editProfesor,
                                    Borrado: e.target.value
                                })
                            }}
                        >
                            <MenuItem value={false}>No</MenuItem>
                            <MenuItem value={true}>Si</MenuItem>
                        </Select>
                    </FormControl>
                    <br/>
                    <Button variant="contained" color="primary" onClick={() => {
                        actualizarProfesor();
                    }}>Guardar</Button>
                </Box>
            </Modal>
        );
    };

    return (
        <>
            <MUIDataTable
                title={"Profesores"}
                data={profesores}
                columns={columns}
                options={options}
            />
            {modalEditar()}
        </>
    );
}