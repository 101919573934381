import React from "react";
import { Container, Card, CardContent, CardMedia, Grid, Typography, Button, IconButton, FormControl, InputLabel } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircle from "@material-ui/icons/CheckCircle";

import { upload, getToken, updateCourse, deleteCourse } from "../../services/api/Api";

export default function Curso({ id, setSeElimino, ...data}) {

    const containerRef = React.useRef(null);

    const [ Activo, setActivo ] = React.useState(data.Activo);
    const [ Banner, setBanner ] = React.useState(data.Banner);
    const [ Borrado, setBorrado ] = React.useState(data.Borrado);
    const [ Fecha_Fin, setFecha_Fin ] = React.useState(data.Fecha_Fin);
    const [ Fecha_Fin_Inscripcion, setFecha_Fin_Inscripcion ] = React.useState(data.Fecha_Fin_Inscripcion);
    const [ Fecha_Inicio, setFecha_Inicio ] = React.useState(data.Fecha_Inicio);
    const [ Fecha_Inicio_Inscripcion, setFecha_Inicio_Inscripcion ] = React.useState(data.Fecha_Inicio_Inscripcion);
    const [ Nombre, setNombre ] = React.useState(data.Nombre);
    const [ Streaming, setStreaming ] = React.useState(data.Streaming);
    const [ editable, setEditable ] = React.useState(false);
    const [ editado, setEditado ] = React.useState(false);
    const [ borrable, setBorrable ] = React.useState(false);

    React.useEffect(() => {
        if(editable && containerRef.current) {
            containerRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [editable]);

    React.useEffect(() => {
        // si el Banner es un archivo valido se hace upload y se asigna la URL obtenida a Banner
        if(Banner instanceof File) {
            upload(Banner).then((url) => {
                setBanner(url);
            });
        }
    }, [Banner]);

    const saveCurso = () => {
        let curso = {
            id: id,
            Activo: Activo,
            Banner: Banner,
            Borrado: Borrado,
            Fecha_Fin: Fecha_Fin,
            Fecha_Fin_Inscripcion: Fecha_Fin_Inscripcion,
            Fecha_Inicio: Fecha_Inicio,
            Fecha_Inicio_Inscripcion: Fecha_Inicio_Inscripcion,
            Nombre: Nombre,
            Streaming: Streaming
        };

        getToken().then((token) => {
            updateCourse(token, curso).then((response) => {
                if(response) {
                    setActivo(curso.Activo);
                    setBanner(curso.Banner);
                    setFecha_Fin(curso.Fecha_Fin);
                    setFecha_Fin_Inscripcion(curso.Fecha_Fin_Inscripcion);
                    setFecha_Inicio(curso.Fecha_Inicio);
                    setFecha_Inicio_Inscripcion(curso.Fecha_Inicio_Inscripcion);
                    setNombre(curso.Nombre);
                    setStreaming(curso.Streaming);

                    setEditado(true);
                    setEditable(false);
                }
            });
        });
    };

    const deleteCurso = () => {
        getToken().then((token) => {
            deleteCourse(token, id).then((response) => {
                if(response) {
                    setBorrado(true);
                    setBorrable(true);
                    setSeElimino(true);
                }
            });
        });
    };

    const deleteSetable = () => {
        return (
            <IconButton onClick={(e) => setBorrable(true)}>
                <DeleteIcon />
            </IconButton>
        );
    };

    const deleteConfirm = () => {
        return (
            <>
                <Button variant="contained" color="secondary" onClick={(e) => deleteCurso()}>
                    Confirmar
                </Button>&nbsp;
                <Button variant="contained" color="primary" onClick={(e) => setBorrable(false)}>
                    Cancelar
                </Button>
            </>
        );
    };

    const showCurso = () => {
        return(
            <Container>
                <Grid container>
                    <Grid item xs={3}>
                        <CardMedia
                            component="img"
                            alt="Banner"
                            image={Banner}
                            title="Banner"
                            className="banner_curso"
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5" component="h5" className="align-end">
                                    <a href={Banner} target="_blank">Ver imagen</a>
                                </Typography>
                                <hr/>
                                <Typography variant="h5" component="h2">
                                    Nombre: {Nombre}
                                </Typography>
                                <Typography variant="body2" component="p">
                                    Fecha de inicio: {Fecha_Inicio}
                                </Typography>
                                <Typography variant="body2" component="p">
                                    Fecha de fin: {Fecha_Fin}
                                </Typography>
                                <Typography variant="body2" component="p">
                                    Fecha de inicio de inscripción: {Fecha_Inicio_Inscripcion}
                                </Typography>
                                <Typography variant="body2" component="p">
                                    Fecha de fin de inscripción: {Fecha_Fin_Inscripcion}
                                </Typography>
                                <Typography variant="body2" component="p">
                                    Streaming: <a href={Streaming} target="_blank">{Streaming}</a>
                                </Typography>
                                <Typography variant="body2" component="p">
                                    Activo: <strong>{Activo ? "Sí" : "No"}</strong>
                                </Typography>
                                <IconButton onClick={(e) => setEditable(true)}>
                                    <EditIcon />
                                </IconButton>
                                {editado ? <CheckCircle color="primary" /> : ""}
                                {borrable ? deleteConfirm() : deleteSetable()}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <br/>
            </Container>
        );
    };

    const editCurso = () => {
        return(
            <Container ref={containerRef}>
                <Grid container>
                    <Grid item xs={3}>
                            <CardMedia
                                component="img"
                                alt="Banner"
                                image={Banner}
                                title="Banner"
                                className="banner_curso"
                            />
                    </Grid>
                    <Grid item xs={9}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5" component="h5" className="align-end">
                                    <a href={Banner} target="_blank">Ver imagen</a>
                                </Typography>
                                <hr/>
                                <FormControl fullWidth>
                                    Banner:
                                    <input type="file" onChange={(e) => {
                                        setBanner(e.target.files[0]);
                                    }}/>
                                </FormControl>
                                <hr/>
                                <FormControl fullWidth>
                                    Nombre:
                                    <input type="text" value={Nombre} onChange={(e) => {
                                        setNombre(e.target.value);
                                    }}/>
                                </FormControl>
                                <FormControl fullWidth>
                                    Fecha de inicio:
                                    <input type="date" value={Fecha_Inicio} onChange={(e) => {
                                        setFecha_Inicio(e.target.value);
                                    }}/>
                                </FormControl>
                                <FormControl fullWidth>
                                    Fecha de finalización:
                                    <input type="date" value={Fecha_Fin} onChange={(e) => {
                                        setFecha_Fin(e.target.value);
                                    }}/>
                                </FormControl>
                                <FormControl fullWidth>
                                    Fecha de inicio de inscripción:
                                    <input type="date" value={Fecha_Inicio_Inscripcion} onChange={(e) => {
                                        setFecha_Inicio_Inscripcion(e.target.value);
                                    }}/>
                                </FormControl>
                                <FormControl fullWidth>
                                    Fecha de fin de inscripción:
                                    <input type="date" value={Fecha_Fin_Inscripcion} onChange={(e) => {
                                        setFecha_Fin_Inscripcion(e.target.value);
                                    }}/>
                                </FormControl>
                                <hr/>
                                <FormControl fullWidth>
                                    Link de streaming:
                                    <input type="text" value={Streaming} onChange={(e) => {
                                        setStreaming(e.target.value);
                                    }}/>
                                </FormControl>
                                <FormControl fullWidth>
                                    Activo:
                                    <input type="checkbox" checked={Activo} onChange={(e) => {
                                        setActivo(e.target.checked);
                                    }}/>
                                </FormControl>
                                <hr/>
                                <Button variant="contained" color="primary" onClick={(e) => saveCurso()}>
                                    Guardar
                                </Button>&nbsp;
                                <Button variant="contained" color="secondary" onClick={(e) => setEditable(false)}>
                                    Cancelar
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <br/>
            </Container>
        );
    };

    return(
        <>
            {editable ? editCurso() : showCurso()}
        </>
    );
};