import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

// styles
import useStyles from "./styles";

// context
import { useUserDispatch, loginUser } from "../../context/UserContext";

// Servicios
import { getToken, getLogo } from "../../services/api/Api";

// Imagenes
import defaultLogo from "../../images/LogoAutomata.png";

// Router
import { Navigate } from "react-router-dom";

const getCurrentLogo = async () => {
  const token = await getToken();
  if(token) {
      let logo = await getLogo(token);
      return logo;
  } else {
      return defaultLogo;
  }
};

function Login({ isAuthenticated, ...props}) {
  var classes = useStyles();
  // global
  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  var [errorDetail, setErrordetail] = useState("");
  var [logo, setLogo] = useState("");

    React.useEffect(() => {
      async function init(){
        const currentLogo = await getCurrentLogo();
        setLogo(currentLogo);
      }

      init();
    }, []);

  if (isAuthenticated) {
    return <Navigate to="/admin/app" />;
  }

  return (
    <Grid container className={classes.container}>
      {/* <div className={classes.logotypeContainer}>
        <img src={logoComision} alt="logo" className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}>Material Admin</Typography>
      </div> */}
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <React.Fragment>
            <Grid container direction={"row"} justifyContent="center">
              <Grid item xs={6} sx={{ pb: 10 }}>
                <img
                  src={logo}
                  alt="logo"
                  className={classes.greeting}
                  style={{ maxWidth: "100%" }}
                />
              </Grid>
            </Grid>
            <div className={classes.formDividerContainer}>
              <div className={classes.formDivider} />
              <Typography className={classes.formDividerWord}>Admin - Login</Typography>
              <div className={classes.formDivider} />
            </div>
            {error && <Alert severity="error">{errorDetail}</Alert>}
            <TextField
              id="email"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={loginValue}
              onChange={(e) => setLoginValue(e.target.value)}
              margin="normal"
              placeholder="Email Adress"
              type="email"
              fullWidth
            />
            <TextField
              id="password"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={passwordValue}
              onChange={(e) => setPasswordValue(e.target.value)}
              margin="normal"
              placeholder="Password"
              type="password"
              fullWidth
            />
            <div className={classes.formButtons}>
              {isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button
                  disabled={
                    loginValue.length === 0 || passwordValue.length === 0
                  }
                  onClick={() =>
                    loginUser(
                      userDispatch,
                      loginValue,
                      passwordValue,
                      setIsLoading,
                      setError,
                      setErrordetail
                    )
                  }
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Login
                </Button>
              )}
            </div>
          </React.Fragment>
        </div>
        <Typography color="primary" className={classes.copyright}>
          ©Automta Digital. All rights reserved.
        </Typography>
      </div>
    </Grid>
  );
}

export default Login;
