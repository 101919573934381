import React from "react";
import { HashRouter, Route, Routes, Navigate } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

// pages
// import ExpaleRecaptcha from '../pages/formulario/ExpaleRecaptcha';
import Login from "../pages/loginFirebase";
// import HomeCertificado from "../pages/certificado/Home";
// components
import Layout from "./Layout";

import HeaderAdmin from "./Header/HeaderAdmin";
import Personalizar from "../pages/personalizar/Personalizar";
import Cursos from "../pages/cursos/Cursos";
import Estudiantes from "../pages/estudiantes/Estudiantes";
import Profesores from "../pages/profesor/Profesores";

// context
import { useUserState } from "../context/UserContext";

// styles
import "./App.css";


export default function App() {
  var { isAuthenticated } = useUserState();

  return (
    <HashRouter>
      <HeaderAdmin/>
      <Routes>
        <Route path="/admin/app" element={
          <PrivateRoute element={<Layout />} isAuthenticated={isAuthenticated} />
        } />
        <Route path="/admin/cursos" element={
          <PrivateRoute element={<Cursos />} isAuthenticated={isAuthenticated} />
        } />
        <Route path="/admin/estudiantes" element={
          <PrivateRoute element={<Estudiantes />} isAuthenticated={isAuthenticated} />
        } />
        <Route path="/admin/personalizar" element={
          <PrivateRoute element={<Personalizar />} isAuthenticated={isAuthenticated} />
        } />

        <Route path="/admin/profesores" element={
          <PrivateRoute element={<Profesores />} isAuthenticated={isAuthenticated} />
        } />
        

        <Route path="/" element={<Navigate to="/admin/app" />} />
        <Route path="/login" element={<Login isAuthenticated={isAuthenticated}/>} />
        <Route path="*" component={Error} />
      </Routes>
    </HashRouter>
  );
}
