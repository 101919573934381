import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  tableContainer: {
    margin: "20px",
    borderRadius: "10px",
    overflow: "hidden",
    backgroundColor: "#333",
    color: "#fff",
  },
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    backgroundColor: "#fff",
    border: "2px solid #ccc",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: 8,
    padding: 16,
    display: "flex",
    flexDirection: "column",
  },
  botonIzq: {
    marginBottom: "10px",
    marginTop: "10px",
    padding: "10px",
    borderRadius: "4px",
    cursor: "pointer",
  },
  tableCell: { backgroundColor: "#444", fontWeight: "bold", color: "#fff" },
}));
