import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({

    card: {
        minWidth: 200,
        maxWidth: 345,
        margin: theme.spacing(2),
    },
    media: {
        height: 200,
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    title: {
        color: '#4267B2',
        textAlign: 'center',
        width: '100%',
    },
}));

function NavigationCard({ title, image, navigateTo }) {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleNavigate = (navigateTo) => {
        return navigate(navigateTo);
    };

    return (
        <Card className={classes.card} onClick={() => handleNavigate(navigateTo)}>
            <CardActionArea>
                <CardMedia
                    className={classes.media}
                    image={image}
                    title={title}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
                        {title}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}


export default NavigationCard;