import React from "react";
import { Grid, Typography, Container, Button } from "@material-ui/core";
import { Link } from "react-router-dom";

import Curso from "./Curso";
import NuevoCurso from "./NuevoCurso";
import { getToken, getCourses } from "../../services/api/Api";

// estilos
import "./styles.css";

export default function Cursos() {
    const [ courses, setCourses ] = React.useState([]);
    const [ nuevoCurso, setNuevoCurso ] = React.useState(false);
    const [ seGuardo, setSeGuardo ] = React.useState(false);
    const [ seElimino, setSeElimino ] = React.useState(false);

    const init = async () => {
        getToken()
            .then((token) => {
                getCourses(token)
                    .then((data) => {
                        setCourses(data);
                    });
            });
    };

    React.useEffect(() => {
        init();
    }, []);

    React.useEffect(() => {
        if(seGuardo) {
            init();
            setSeGuardo(false);
            setNuevoCurso(false);
        }
    }, [seGuardo]);

    React.useEffect(() => {
        if(seElimino) {
            init();
            setSeElimino(false);
        }
    }, [seElimino]);

    return (
        <Container className="contenido">
            <Grid>
                <Grid container>
                    <Grid item>
                        <Link to="/admin/app">
                            <Button variant="contained" color="primary">
                                Volver
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item xs={9}>
                        <h1>Cursos</h1>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={(e) => setNuevoCurso(true)}>
                            Nuevo
                        </Button>
                    </Grid>
                </Grid>
                <br />
                {nuevoCurso && (
                    <>
                        <NuevoCurso setSeGuardo={setSeGuardo} setNuevoCurso={setNuevoCurso} />
                        <br/>
                    </>
                )}
                {courses.map((course) => (
                    <Grid item xs={12} key={course.key}>
                        <Curso id={course.key} {...course} setSeElimino={setSeElimino} />
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};