import React, { useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";

// Iconos
import { Edit, CloudDownload } from "@material-ui/icons";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import config from "../../config";

// Servicios de API
import { getToken, getStudents, updateStudent } from "../../services/api/Api";

// styles
import useStyles from "./styles";

export default function TablesEstudainte() {
  var classes = useStyles();

  const [Id, setId] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const cerrarModal = () => setOpen(false);
  const [datos, setDatos] = React.useState({});

  const [loading, setLoading] = React.useState(true);
  const [visible, setVisible] = React.useState(true);


  const columnas = [
    {
      name: "key",
      options: {
        display: "excluded", // Oculta la columna 'id' en la tabla
      },
    },
    {
      name: "Colegiado",
      label: "Colegiado",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Nombre",
      label: "Nombre",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Telefono",
      label: "Teléfono",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Correo",
      label: "Correo",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Pago",
      label: "Pagó",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <span style={{ color: value ? "green" : "red" }}>
            {value ? "Pagado" : "No pagado"}
          </span>
        ),
      },
    },
    {
      name: "actions",
      label: "Acciones",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleEdit(tableMeta.rowData)}
              >
                <Edit />
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleDownload(tableMeta.rowData)}
              >
                <CloudDownload />
              </Button>
            </>
          );
        },
      },
    }
  ];


  const updateState = (id) => {
    let clientes = datosClientes;
    let cliente = clientes.find((cliente) => cliente.key === id);
    let index = clientes.indexOf(cliente);
    
    cliente.Colegiado = datos.colegiado;
    cliente.Nombre = datos.nombre;
    cliente.Telefono = datos.telefono;
    cliente.Correo = datos.correo;
    cliente.Pago = datos.pago;
    cliente.Borrado = (datos.borrado === "Sí" ? true : false);

    if (datos.borrado === "Sí") {
      clientes = clientes.splice(index, 1);
    }

    setDatosClientes(clientes);
    cerrarModal();
  };

  const editar = () => {
    getToken().then((token) => {
      let estudiante = {
        id: Id,
        Colegiado: datos.colegiado,
        Nombre: datos.nombre,
        Telefono: datos.telefono,
        Correo: datos.correo,
        Pago: datos.pago,
        Borrado: (datos.borrado === "Sí" ? true : false)
      }
      updateStudent(token, estudiante).then((response) => {
        console.log(response);
        updateState(Id);
      });
    });
  };

  const handleEdit = (rowData) => {
    setOpen(true);
    setId(rowData[0]);
    setDatos({
      colegiado: rowData[1],
      nombre: rowData[2],
      telefono: rowData[3],
      correo: rowData[4],
      pago: rowData[5],
      borrado: undefined,
    });
  };

  const handleDownload = (rowData) => {
    // console.log("Download: ", rowData);
    let stringData = {
      idFirebase: rowData[0],
      nombre: rowData[2],
      numero: rowData[1],
      profesion: "EST",
    };

    fetch(`${config.server}/getToken`, {
      method: "POST",
      body: JSON.stringify({
        username: "corpoca",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        fetch(`${config.server}/diploma`, {
          method: "POST",
          body: JSON.stringify(stringData),
          headers: {
            "Content-Type": "application/json",
            Authorization: data.token,
          },
        })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `Diploma_CongresoMedico_${stringData.numero}.pdf`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          });
      });
  };

  const [datosClientes, setDatosClientes] = React.useState([]);

  const fetchData = async () => {
    getToken().then((token) => {
      getStudents(token).then((data) => {
        if (data.length < 1 && datosClientes.length < 1) return fetchData();

        setDatosClientes(data);
        return;
      });
    });
  };

  useEffect(() => {
    fetchData();
    setVisible(false);
    setVisible(true);
    setLoading(false);
  }, []);

  const handleChangeCarnet = (event) => {
    setDatos({
      ...datos,
      carnet: event.target.value,
    });
  };
  const handleChangeNombre = (event) => {
    setDatos({
      ...datos,
      nombre: event.target.value,
    });
  };
  const handleChangeTelefono = (event) => {
    setDatos({
      ...datos,
      telefono: event.target.value,
    });
  };
  const handleChangeCorreo = (event) => {
    setDatos({
      ...datos,
      correo: event.target.value,
    });
  };
  const handleChangePago = (event) => {
    setDatos({
      ...datos,
      pago: event.target.value,
    });
  };



  return (
    <>
      <PageTitle title="Registros de los usuarios estudiantes" />
      {loading && (
        <div>
          loading...
          <CircularProgress />
        </div>
      )}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {visible && (
            <MUIDataTable
              options={{
                selectableRows: "none",
              }}
              data={datosClientes}
              columns={columnas}
            />
          )}
        </Grid>
      </Grid>

      <Modal open={open} onClose={cerrarModal}>
        <Box className={classes.modal}>
          <Typography
            variant="h5"
            style={{ fontWeight: "bold", marginBottom: "5px" }}
          >
            Editar Usuario
          </Typography>
          <TextField
            style={{ marginTop: 10, marginBottom: 10 }}
            fullWidth
            value={datos?.colegiado}
            onChange={handleChangeCarnet}
            id="colegiado"
            label="Colegiado"
            variant="outlined"
          />
          <TextField
            style={{ marginTop: 10, marginBottom: 10 }}
            required
            fullWidth
            value={datos?.nombre}
            onChange={handleChangeNombre}
            id="nombreCompleto"
            label="Nombre completo"
            variant="outlined"
          />
          <TextField
            style={{ marginTop: 10, marginBottom: 10 }}
            required
            fullWidth
            value={datos?.telefono}
            onChange={handleChangeTelefono}
            id="telefono"
            label="Número de teléfono"
            variant="outlined"
          />
          <TextField
            style={{ marginTop: 10, marginBottom: 10 }}
            required
            fullWidth
            value={datos?.correo}
            type="text"
            onChange={handleChangeCorreo}
            id="correoElectronico"
            label="Correo electrónico"
            variant="outlined"
          />

          <FormControl
            variant="filled"
            fullWidth
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <InputLabel>Pago</InputLabel>
            <Select
              value={datos?.ago}
              label="pago"
              onChange={handleChangePago}
              defaultValue={datos?.pago}
            >
              <MenuItem value={true}>Pagado</MenuItem>
              <MenuItem value={false}>No Pagado</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            variant="filled"
            fullWidth
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <InputLabel>Borrar</InputLabel>
            <Input
              value={datos?.borrado}
              placeholder="Escriba Sí y Guarde para borrar el registro"
              onChange={(event) => {
                setDatos({
                  ...datos,
                  borrado: event.target.value,
                });
              }} />
          </FormControl>
          <Button
            variant="contained"
            className={classes.botonIzq}
            onClick={editar}
          >
            Guardar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.botonIzq}
            onClick={cerrarModal}
          >
            Cerrar
          </Button>
        </Box>
      </Modal>
    </>
  );
}
