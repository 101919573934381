import React from "react";
import { Link } from "react-router-dom";
import { Grid, Button, Container, FormControl, Card, CardContent, CardMedia } from "@material-ui/core";
import { getToken, getLogo, getSlogan, updateLogo, updateSlogan, upload } from "../../services/api/Api";

// Iconos
// Icono de check
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

// Imagenes
import defaultLogo from "../../images/LogoAutomata.png";

// Estilos
import "./styles.css";

// asincronas
const getCurrentLogo = async () => {
    const token = await getToken();
    if(token) {
        const logo = await getLogo(token);
        return logo;
    } else {
        return defaultLogo;
    }
};

const getCurrentSlogan = async () => {
    const token = await getToken();
    if(token) {
        const slogan = await getSlogan(token);
        return slogan;
    } else {
        return "";
    }
};

const Personalizar = () => {
    const [ logo, setLogo ] = React.useState("");
    const [ slogan, setSlogan ] = React.useState("Slogan");
    const [ newLogo, setNewLogo ] = React.useState(undefined);
    const [ logo_changed, setLogoChanged ] = React.useState(false);
    const [ slogan_changed, setSloganChanged ] = React.useState(false);

    React.useEffect(() => {
        async function init(){
            const currentLogo = await getCurrentLogo();
            setLogo(currentLogo);
            const currentSlogan = await getCurrentSlogan();
            setSlogan(currentSlogan);
        }

        init();
    }, []);

    React.useEffect(() => {
        // Sí newLogo es un archivo válido, extraer la URL temporal y asignarla a logo
        if(newLogo) {
            const url = URL.createObjectURL(newLogo);
            setLogo(url);
        }
    }, [newLogo]);

    const handleSetLogo = async () => {
        const token = await getToken();
        if(token) {
            const url = await upload(newLogo);
            if(url) {
                await updateLogo(token, url);
                const currentLogo = await getCurrentLogo();
                setLogo(currentLogo);
                setLogoChanged(true);
            }
        }
    }

    const handleSetSlogan = async () => {
        const token = await getToken();
        if(token) {
            await updateSlogan(token, slogan);
            const currentSlogan = await getCurrentSlogan();
            setSlogan(currentSlogan);
            setSloganChanged(true);
        }
    }

    return (
        <Container maxWidth="lg" style={{ marginTop: "20px" }}>
            <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item>
                            <Link to="/admin/app">
                                <Button variant="contained" color="primary">
                                    Volver
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item>
                            <h1>Personalizar</h1>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item lg={4} xs={12}>
                            <Card>
                                <CardMedia
                                    image={logo}
                                    title="Logo"
                                    className="logo"
                                />
                                <CardContent margintop={10} >
                                    <FormControl fullWidth>
                                        <input type="file" onChange={(e) => {
                                            setNewLogo(e.target.files[0]);
                                        }}/>
                                    </FormControl>
                                    <hr />
                                    <Button variant="contained" color="primary" onClick={handleSetLogo}>
                                        Cambiar logo
                                    </Button>
                                    {logo_changed && <CheckCircleIcon color="primary" />}
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item lg={8} xs={12}>
                            <Card>
                                <CardContent>
                                    <FormControl fullWidth>
                                        <textarea value={slogan} onChange={(e) => setSlogan(e.target.value)} rows={11} />
                                    </FormControl>
                                    <hr/>
                                    <Button variant="contained" color="primary" onClick={handleSetSlogan}>
                                        Cambiar slogan
                                    </Button>
                                    {slogan_changed && <CheckCircleIcon color="primary" />}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Personalizar;