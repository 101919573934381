import React, { useEffect } from "react";
import { auth, watchUserChanges } from "../services/firebase"

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  // console.log(state, action)
  switch (action.type) {
    /*case "AGREGAR_HISTORY":
      return { ...state, history: action.history }*/
    case "LOGIN_SUCCESS":
      if (state.history !== null) { state.history.push('/admin/app'); }
      return { ...state, isAuthenticated: true, user: action.user };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false, user: null };
    /*case "LOGIN_FAILURE":
      return { ...state, isAuthenticated: false, user: null, history: null };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }*/
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: false,
    user: null,
    history: null,
  });


  useEffect(() => {
    watchUserChanges((user) => {
      if (user) {
        dispatch({ type: 'LOGIN_SUCCESS', user: user })

      } else {
        //dispatch({ type: 'LOGIN_FAILURE' })
      }
    });

  }, []);

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################

function loginUser(dispatch, login, password, setIsLoading, setError, setErrordetail) {
  setError(false);
  setIsLoading(true);

  if (!!login && !!password) {

    setError(false);
    setIsLoading(true);

    auth.signInWithEmailAndPassword(login, password).then(
      function () {

        localStorage.setItem('id_token', 1);
        //dispatch({ type: 'AGREGAR_HISTORY' })

        setError(false);
        setIsLoading(false);

      }
    ).catch(error => {
      setErrordetail(error.message);
      console.error("Error signing in with password and email", error);

      setError(true);
      setIsLoading(false);
    });

  } else {
    //dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
  }
}

function signOut(dispatch) {
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  auth.signOut();
}
