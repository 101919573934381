import React from "react";

// context
import { Grid, } from "@material-ui/core";

// images
import Custom_Icon from "../../images/Custom_Icon.png";
import Courses_Icon from "../../images/Courses_Icon.png";
import Students_Icon from "../../images/Students_Icon.png";

import NavigationCard from "./NavigationCard";


function HomeAdmin() {

  // Crearemos 3 cards, una para personalizar, otra para ver los cursos y otra para ver los estudiantes,
  // Cada una de estas será un botón para navegar a la página correspondiente
  // Debe tener espacio para colocar una imagen a modo de icono y un texto

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item>
              <NavigationCard
                title="Personalizar"
                image={Custom_Icon}
                navigateTo="/admin/personalizar"
              />
            </Grid>
            <Grid item>
              <NavigationCard
                title="Cursos"
                image={Courses_Icon}
                navigateTo="/admin/cursos"
              />
            </Grid>
            <Grid item>
              <NavigationCard
                title="Profesores"
                image={Students_Icon}
                navigateTo="/admin/profesores"
              />
            </Grid>
            <Grid item>
              <NavigationCard
                title="Estudiantes"
                image={Students_Icon}
                navigateTo="/admin/estudiantes"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default HomeAdmin;
