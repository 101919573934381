import React from "react";

import { Container, Grid, Button } from "@material-ui/core";

import { Link } from "react-router-dom";

import TablesEstudiante from "../tables/TablesEstudiante";

export default function Estudiantes() {
    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item>
                    <Link to="/admin/app">
                        <Button variant="contained" color="primary">Volver</Button>
                    </Link>
                </Grid>
                <Grid item xs={8} className="align-center">
                    <h1>Estudiantes</h1>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TablesEstudiante />
                </Grid>
            </Grid>
        </Container>
    );
}