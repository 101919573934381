import React from "react";
import { Container, Card, CardContent, CardMedia, Grid, Typography, Button,  FormControl } from "@material-ui/core";

import { getToken, createCourse, getLogo, upload } from "../../services/api/Api";

import { MD5 } from "crypto-js";

import "./styles.css";

export default function NuevoCurso({ setSeGuardo, setNuevoCurso }) {

    const containerRef = React.useRef(null);

    const [Activo, setActivo] = React.useState(false);
    const [Banner, setBanner] = React.useState("");
    const [Fecha_Fin, setFecha_Fin] = React.useState("");
    const [Fecha_Fin_Inscripcion, setFecha_Fin_Inscripcion] = React.useState("");
    const [Fecha_Inicio, setFecha_Inicio] = React.useState("");
    const [Fecha_Inicio_Inscripcion, setFecha_Inicio_Inscripcion] = React.useState("");
    const [Nombre, setNombre] = React.useState("");
    const [Streaming, setStreaming] = React.useState("");

    const Borrado = false;

    const [NombreProfesor, setNombreProfesor] = React.useState("");
    const [CorreoProfesor, setCorreoProfesor] = React.useState("");
    const [ContrasenaProfesor, setContrasenaProfesor] = React.useState("");
    const [TelefonoProfesor, setTelefonoProfesor] = React.useState("");

    React.useEffect(() => {
        const init = async () => {
            const token = await getToken();
            if (token) {
                const logo = await getLogo(token);
                setBanner(logo);
            }
        };
        init();
    }, []);

    React.useEffect(() => {
        // si el Banner es un archivo valido se hace upload y se asigna la URL obtenida a Banner
        if(Banner instanceof File) {
            upload(Banner).then((url) => {
                setBanner(url);
            });
        }
    }, [Banner]);

    const saveCurso = async () => {
        const token = await getToken();
        if (token) {
            const curso = {
                Activo,
                Banner,
                Fecha_Fin,
                Fecha_Fin_Inscripcion,
                Fecha_Inicio,
                Fecha_Inicio_Inscripcion,
                Nombre,
                Streaming,
                Borrado,
            };
            createCourse(token, curso).then((data) => {
                setSeGuardo(true);
                setNuevoCurso(false);
            });
        }
    };

    const formulario = () => {
        return (
            <Container ref={containerRef}>
                <Grid container>
                    <Grid item xs={3}>
                        <CardMedia
                            component="img"
                            alt="Banner"
                            image={Banner}
                            title="Banner"
                            className="banner_curso"
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5" component="h5" className="align-end">
                                    <a href={Banner} target="_blank">Ver imagen</a>
                                </Typography>
                                <hr />
                                <FormControl fullWidth>
                                    Banner:
                                    <input type="file" onChange={(e) => {
                                        setBanner(e.target.files[0]);
                                    }} />
                                </FormControl>
                                <hr />
                                <FormControl fullWidth>
                                    Nombre:
                                    <input type="text" value={Nombre} onChange={(e) => {
                                        setNombre(e.target.value);
                                    }} />
                                </FormControl>
                                <FormControl fullWidth>
                                    Fecha de inicio:
                                    <input type="date" value={Fecha_Inicio} onChange={(e) => {
                                        setFecha_Inicio(e.target.value);
                                    }} />
                                </FormControl>
                                <FormControl fullWidth>
                                    Fecha de finalización:
                                    <input type="date" value={Fecha_Fin} onChange={(e) => {
                                        setFecha_Fin(e.target.value);
                                    }} />
                                </FormControl>
                                <FormControl fullWidth>
                                    Fecha de inicio de inscripción:
                                    <input type="date" value={Fecha_Inicio_Inscripcion} onChange={(e) => {
                                        setFecha_Inicio_Inscripcion(e.target.value);
                                    }} />
                                </FormControl>
                                <FormControl fullWidth>
                                    Fecha de fin de inscripción:
                                    <input type="date" value={Fecha_Fin_Inscripcion} onChange={(e) => {
                                        setFecha_Fin_Inscripcion(e.target.value);
                                    }} />
                                </FormControl>
                                <hr />
                                <FormControl fullWidth>
                                    Link de streaming:
                                    <input type="text" value={Streaming} onChange={(e) => {
                                        setStreaming(e.target.value);
                                    }} />
                                </FormControl>
                                <FormControl fullWidth>
                                    Activo:
                                    <input type="checkbox" checked={Activo} onChange={(e) => {
                                        setActivo(e.target.checked);
                                    }} />
                                </FormControl>
                                <hr />
                                <Button variant="contained" color="primary" onClick={(e) => saveCurso()}>
                                    Guardar
                                </Button>&nbsp;
                                <Button variant="contained" color="secondary" onClick={(e) => setNuevoCurso(false)}>
                                    Cancelar
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <br />
            </Container>
        );
    };

    return (
        <Container>
            <Grid container>
                <Grid item xs={12}>
                    {formulario()}
                </Grid>
            </Grid>
        </Container>
    );
};