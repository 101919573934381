import React from "react";
import { Grid, Typography, Container, Button, Modal, Box, FormControl, InputLabel, Input } from "@material-ui/core";
import { Link } from "react-router-dom";

import TablaProfesores from "./TablaProfesores";

import { getToken, getTeachers, createTeacher } from "../../services/api/Api";

import useStyles from "./styles";


export default function Profesores() {
    const [ profesores, setProfesores ] = React.useState([]);
    const [ nuevoProfesor, setNuevoProfesor ] = React.useState(false);
    const [ seGuardo, setSeGuardo ] = React.useState(false);
    const [ seElimino, setSeElimino ] = React.useState(false);
    const [ nombre, setNombre ] = React.useState('');
    const [ correo, setCorreo ] = React.useState('');
    const [ contrasena, setContrasena ] = React.useState('');
    const [ telefono, setTelefono ] = React.useState('');
    const [ cursos, setCursos ] = React.useState([]);

    var classes = useStyles();

    const init = async () => {
        getTeachers().then((data) => {
            setProfesores(data);
        });
    };

    React.useEffect(() => {
        init();
    }, []);

    React.useEffect(() => {
        if(seGuardo) {
            init();
            setSeGuardo(false);
            setNuevoProfesor(false);
        }
    }, [seGuardo]);

    React.useEffect(() => {
        if(seElimino) {
            init();
            setSeElimino(false);
        }
    }, [seElimino]);

    const crearProfesor = async () => {
        let payload = {
            Nombre: nombre,
            Correo: correo,
            Contrasena: contrasena,
            Telefono: telefono,
            Profesor: true,
            Borrado: false
        };
        createTeacher(payload).then((data) => {
            setSeGuardo(true);
            setNuevoProfesor(false);
            console.log(data);
        }).catch((error) => {
            console.log(error);
        });
    }

    const newProfesorModal = () => {
        return (
            <Modal open={nuevoProfesor} onClose={() => setNuevoProfesor(false)}>
                <Box className={classes.modal}>
                    <Typography variant="h6" className="titulo">Nuevo profesor</Typography>
                    <FormControl fullWidth>
                        <InputLabel>Nombre</InputLabel>
                        <Input type="text" onChange={(e) => setNombre(e.target.value)} />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Correo</InputLabel>
                        <Input type="email" onChange={(e) => setCorreo(e.target.value)} />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Teléfono</InputLabel>
                        <Input type="text" onChange={(e) => setTelefono(e.target.value)} />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Asignar nueva contraseña</InputLabel>
                        <Input type="password" onChange={(e) => setContrasena(e.target.value)} />
                    </FormControl>
                    <Button variant="contained" color="primary" onClick={() => {
                        crearProfesor();
                    }}>Crear</Button>
                </Box>
            </Modal>
        );
    };

    return (
        <Container className="contenido">
            <Grid>
                <Grid container>
                    <Grid item xs={2}>
                        <Link to="/admin/app">
                            <Button variant="contained" color="primary" style={{ marginTop: "20px" }}>
                                Volver
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item xs={8}>
                        <h1>Profesores</h1>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'right' }}>
                        <Button variant="contained" color="primary" onClick={(e) => setNuevoProfesor(true)} style={{ marginTop: "20px" }}>
                            Nuevo
                        </Button>
                    </Grid>
                </Grid>
                <br />
                <TablaProfesores profesores={profesores} setProfesores={setProfesores} seElimino={seElimino} setSeElimino={setSeElimino} />
            </Grid>
            {newProfesorModal()}
        </Container>
    );
};